.post-content {
    margin-top: 16px;
}

tt {
    background: rgb(235, 235, 235);
}

.post-content {
    margin-bottom: 130px;
}

.back-to-top {
    text-align: center;
    color: #4582ec;
}

.quote {
    background: rgb(237 237 237);
    padding: 10px 25px;
}

.react-p5 {
    text-align: center;
    margin-bottom: 50px;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

/* .container {
  border: 1px solid white;  
} */

ul.navbar-nav li a {
  font-size: 20px;
}

ul.navbar-nav li a:hover {
  text-decoration: line-through;
}

.navbar-brand h3 {
  font-family: 'Roboto Mono', monospace;

}

.about {
  padding: 15px;
}
@media (min-width: 1370px) {
  .container {
    width: 60%;
  }
}

#navbar {
  padding: 10px;
}

.blockquote, tt {
  color: black;
}
.card-tags .badge {
    margin-right: 5px;
}

.card {
    cursor: pointer;
}

.card-link:hover, .card-link:visited, .card-link:link, .card-link:active, .card-link
{
    text-decoration: none;
    color: inherit;
}
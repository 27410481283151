.container {
    margin-top: 20px;
}

.img-me-wrapper {
    background-size: contain;
}

.img-me {
    /* border: 1px solid black; */
    border-radius: 100%;
    height: 200px;
    width: 200px;
    margin: 0 auto; 
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media (max-width: 990px) {
    .me-info {
        margin-top: 20px;
    }
}


#about-me {
    margin-bottom: 30px;
}

.img-me img {
    width: 350px;
    height: auto;
}
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@media (min-width: 1370px) {
  .container {
    width: 60%;
  }
}

#navbar {
  padding: 10px;
}

.blockquote, tt {
  color: black;
}
nav {
    padding: 5px 5px 5px 15px;
}
/* .container {
  border: 1px solid white;  
} */

ul.navbar-nav li a {
  font-size: 20px;
}

ul.navbar-nav li a:hover {
  text-decoration: line-through;
}

.navbar-brand h3 {
  font-family: 'Roboto Mono', monospace;

}

.about {
  padding: 15px;
}
.card-tags .badge {
    margin-right: 5px;
}

.card {
    cursor: pointer;
}

.card-link:hover, .card-link:visited, .card-link:link, .card-link:active, .card-link
{
    text-decoration: none;
    color: inherit;
}
.container {
    margin-top: 20px;
}

.img-me-wrapper {
    background-size: contain;
}

.img-me {
    /* border: 1px solid black; */
    border-radius: 100%;
    height: 200px;
    width: 200px;
    margin: 0 auto; 
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media (max-width: 990px) {
    .me-info {
        margin-top: 20px;
    }
}


#about-me {
    margin-bottom: 30px;
}

.img-me img {
    width: 350px;
    height: auto;
}
.topics {
    margin-bottom: 20px;
}

.topic-btn {
    margin: 0px 0px 5px 5px;
    padding: 6px;
}
form {
    width: 50%;
}

.main-container {
    box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.59);
-webkit-box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.59);
-moz-box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.59);
margin-bottom: 100px;
}

@media (max-width: 1630px) {
    .main-container {
      width: 70%;
    }
}

@media (max-width: 1310px) {
    .main-container {
      width: 95%;
    }
}

.main-container {
    padding-bottom: 20px;
}

/*Custom*/
#name-h2 {
    margin-bottom: 0px;
}

#title-h3 {
    margin-top: -5px;
}

#contact-me-h4 {
    margin-top: 50px;
}

.contact-info-wrapper {
    width: 70%;
    display: -webkit-flex;
    display: flex;
}
.contact-info {
    font-size: 20px;
    width: 50%;
    display: grid;
}

.contact-info small {
    padding: 8px;
}

i {
    margin-right: 5px;
}

.img-wrapper {
    text-align: center;
}

.img-wrapper .img-circle {
    border-radius: 100%;
    overflow: hidden;
    border: 3px solid rgb(69,130,236);
    width: 250px;
    height: 250px;
    margin: 0 auto;
    background-size: contain;
}

.img-wrapper img {
    width: 70%;
}

.exp-title {
    margin-left: 50px;
    margin-bottom: 20px;
}

.me-info-container {
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 50px;
    border-bottom: 2px solid #868e96;
}
.cd-container {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    /* border: 1px solid black;  */
    /* background: #2b343a; */
    border-radius: 2px;
}
.cd-container::after {
    content: '';
    display: table;
    clear: both;
}
/* -------------------------------- Main components -------------------------------- */
#cd-timeline {
    position: relative;
    padding: 1em 0;
    margin-top: 1em;
    margin-bottom: 1em;
}
#cd-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 2.5px;
    background:rgb(69,130,236);
}
@media only screen and (min-width: 1170px) {
    #cd-timeline {
        margin-top: 1em;
        margin-bottom: 1em;
   }
    #cd-timeline::before {
        left: 50%;
        margin-left: -2px;
   }
}
.cd-timeline-block {
    position: relative;
    margin: 1em 0;
}
.cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
}
.cd-timeline-block:first-child {
    margin-top: 0;
}
.cd-timeline-block:last-child {
    margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
    .cd-timeline-block {
        margin: 1em 0;
   }
    .cd-timeline-block:first-child {
        margin-top: 0;
   }
    .cd-timeline-block:last-child {
        margin-bottom: 0;
   }
}
.cd-timeline-img {
    position: absolute;
    top: 8px;
    left: 12px;
    width: 30px;
    height: 30px;
    /* border-radius: 50%; */
    background-size: cover;
    padding: 5px;

    /* box-shadow: 0 0 0 3px rgb(5, 90, 236), inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 3px rgba(0, 0, 0, 0.05); */
}


@media only screen and (min-width: 1170px) {
    .cd-timeline-img {
        width: 45px;
        height: 45px;
        left: 49.3%;
        margin-left: -15px;
        margin-top: 5px;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        
   }
}
.cd-timeline-content {
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    background: #ffffff;
    border: 1px solid black; /*here*/
    border-radius: 2px;
    padding: 1em;
}
.cd-timeline-content .timeline-content-info {
    background: #ffffff;
    /* padding: 4px 10px; */
    color: rgb(92, 92, 92); 
    font-size: 13px;
    /* box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.08); */
    /* border-radius: 2px; */
}

.timeline-content-info-date {
    margin-left: 13px; 
}

.cd-timeline-content .timeline-content-info i {
    margin-right: 3px;
    margin-left: 0px;
}
.cd-timeline-content .timeline-content-info .timeline-content-info-title, .cd-timeline-content .timeline-content-info .timeline-content-info-date {
    /* width: calc(50% - 2px); */
    display: inline-block;
}
@media (max-width: 500px) {
    .cd-timeline-content .timeline-content-info .timeline-content-info-title, .cd-timeline-content .timeline-content-info .timeline-content-info-date {
        display: block;
        width: 100%;
   }
}
.cd-timeline-content .content-skills {
    font-size: 15px;
    padding: 0;
    margin-bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}
.content-skills span {
    margin: 3px;
}
/* .cd-timeline-content .content-skills li {
    background: #40484d;
    border-radius: 2px;
    display: inline-block;
    padding: 2px 10px;
    color: rgba(255, 255, 255, 0.7);
    margin: 3px 2px;
    text-align: center;
    flex-grow: 1;
} */
.cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
}
.cd-timeline-content h2 {
    color:black;
    margin-top: 0;
    margin-bottom: 5px;
}
.cd-timeline-content p, .cd-timeline-content .cd-date {
    color: black;
    font-size: 13px;
    font-size: 0.8125rem;
}
.cd-timeline-content .cd-date {
    display: inline-block;
}
.cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
}
.cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #333c42;
}
@media only screen and (min-width: 768px) {
    .cd-timeline-content h2 {
        font-size: 20px;
        font-size: 1.25rem;
   }
    .cd-timeline-content p {
        font-size: 16px;
        font-size: 1rem;
   }
    .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
        font-size: 14px;
        font-size: 0.875rem;
   }
}
@media only screen and (min-width: 1170px) {
    .cd-timeline-content {
        color: black;
        margin-left: 0;
        padding: 1em;
        width: 42%;
        margin: 0 2%;
   }
    .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: #333c42;
   }
    .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px;
        font-size: 16px;
        font-size: 1rem;
   }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
        float: right;
   }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: #333c42;
   }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
        float: right;
   }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
        left: auto;
        right: 125%;
        text-align: right;
        top: 10%;
        font-weight: bold;
   }
}

.exp-title {
    margin-bottom: 20px;
}


.post-content {
    margin-top: 16px;
}

tt {
    background: rgb(235, 235, 235);
}

.post-content {
    margin-bottom: 130px;
}

.back-to-top {
    text-align: center;
    color: #4582ec;
}

.quote {
    background: rgb(237 237 237);
    padding: 10px 25px;
}

.react-p5 {
    text-align: center;
    margin-bottom: 50px;
}
